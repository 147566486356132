import './MainSection.css';
import React from 'react';
import HomeEllipse from '../../assets/img/main_page_ellipse.png'
import MainPartImg from '../../assets/img/main_page_img.png'
import MainTitleImg from '../../assets/img/SSC Banner 2.png'
import GradientFillImg from '../../assets/img/Gradient Fill 1.png'

const MainSection = () => {
    return (
        <div className='mainSection'>
            <div className='main_part'>
                <div className='main_part_img'>
                    <img src={MainPartImg} />
                </div>
                <div className="main_part_title">
                    <img src={MainTitleImg} />
                    <p>Art - Rewards - Content - Charity</p>
                </div>
                <img src={HomeEllipse} className='home_ellipse_img' />
            </div>
            <img src={GradientFillImg} className='home_grident_img' />
        </div >
    )
}

export default MainSection