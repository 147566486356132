import './MerchMainSection.css';
import React from 'react';
import HomeEllipse from '../../assets/img/main_page_ellipse.png'
import MerchPartImg from '../../assets/img/merch_page_img.png'
import MerchTitleImg from '../../assets/img/merch_banner.png'
import GradientFillImg from '../../assets/img/Gradient Fill 1.png'

const MerchMainSection = () => {
    return (
        <div className='merchSection'>
            <div className='merch_part'>
                <div className='merch_part_img'>
                    <img src={MerchPartImg} />
                </div>
                <div className="merch_part_title">
                    <img src={MerchTitleImg} />
                </div>
            </div>
        </div >
    )
}

export default MerchMainSection