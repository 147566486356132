import './SprayMainSection.css';
import React from 'react';
import HomeEllipse from '../../assets/img/main_page_ellipse.png'
import MainPartImg from '../../assets/img/spray_page_img.png'
import SprayTitleImg from '../../assets/img/spray_banner.png'
import GradientFillImg from '../../assets/img/Gradient Fill 1.png'

const SprayMainSection = () => {
    return (
        <div className='spraySection'>
            <div className='spray_part'>
                <div className='spray_part_img'>
                    <img src={MainPartImg} />
                </div>
                <div className="spray_part_title">
                    <img src={SprayTitleImg} />
                    <p>Keeps your Eyes peeled on our Socials for this one!!!</p>
                </div>
            </div>
        </div >
    )
}

export default SprayMainSection