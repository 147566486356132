import MintMain from "../components/MintMainSection/MintMainSection"

const Mint = (props) => {
    const { currentY } = props;
    return (
        <>
            <div className="mint">
                <MintMain />
            </div>
        </>
    )
}

export default Mint