import MainSection from "../components/MainSection/MainSection"
import DescriptionSection from "../components/DescriptionSection/DescriptionSection"

const Home = (props) => {
    const { currentY } = props;
    return (
        <>
            <div className="home">
                <MainSection />
                <DescriptionSection currentY={currentY} />
            </div>
        </>
    )
}

export default Home