import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ToogleMenu from '../../../assets/img/icons8-menu-30.png'
import './navbar.css'
import twitter from '../../../assets/img/icons8-twitterx-24.png'
import youtube from '../../../assets/img/icons8-youtube-24.png'
import instram from '../../../assets/img/icons8-instagram-16.png'
import mid from '../../../assets/img/icons8-tiktok-16.png'
import mail from '../../../assets/img/icons8-discord-16.png'

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const [isActivePage, setIsActivePage] = useState('/home');

    // useEffect(() => {
    //     setIsActivePage(window.location.pathname);
    //     // console.log(window.location.pathname);
    //     console.log(isActivePage);
    // });

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const setActivePage = (url) => {
        setIsActivePage(window.location.pathname);
        console.log(isActivePage);
    };

    return (
        <div className="navbar">
            <div className='navcontent'>
                <div className={`nav-elements`}>
                    <ul>
                        <li className={`${window.location.pathname === '/' ? 'active' : ''}`}>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li className={`${window.location.pathname === '/mint' ? 'active' : ''}`}>
                            <NavLink to="/mint">Mint</NavLink>
                        </li>
                        <li className={`${window.location.pathname === '/staking' ? 'active' : ''}`}>
                            <NavLink to="/staking">Staking</NavLink>
                        </li>
                        <li className={`${window.location.pathname === '/spray' ? 'active' : ''}`}>
                            <NavLink to="/spray">Spray-FM</NavLink>
                        </li>
                        <li className={`${window.location.pathname === '/merch' ? 'active' : ''}`}>
                            <NavLink to="/merch">Merch</NavLink>
                        </li>
                    </ul>
                </div>
                <div className='special_part'>
                    {/* <div className='connect_wallet_btn'>
                        <NavLink to="/download"><span>Connect Wallet</span></NavLink>
                    </div> */}
                    <div className='social_icons'>
                        <a href='https://x.com/solanasprayclub?s=21&t=ddyb50REeTpKLuRASgopsw' target="_blank">
                            <img src={twitter} alt='twitter' />
                        </a>
                        <a href='https://www.youtube.com/@Solanasprayclub' target="_blank">
                            <img src={youtube} alt='youtube' />
                        </a>
                        <a href='https://www.instagram.com/solanasprayclub?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr' target="_blank">
                            <img src={instram} alt='instram' />
                        </a>
                        <a href='https://www.tiktok.com/@solanasprayclub?_t=8jVFMtMlfEG&_r=1' target="_blank">
                            <img src={mid} alt='mid' />
                        </a>
                        <a href='https://discord.gg/TsBQeyX47b' target="_blank">
                            <img src={mail} alt='mail' />
                        </a>
                    </div>
                </div>
                <div className="toogle_menu" onClick={handleShowNavbar}>
                    <img src={ToogleMenu} />
                </div>

                <div className={`toolbar  ${showNavbar === true ? 'toolbar_block' : 'toolbar_none'}`}>
                    <div className='toolbar_header'>
                        <span className="toolbar_close" onClick={handleShowNavbar}>×</span>
                    </div>
                    <ul className='toolbar_body'>
                        <li onClick={handleShowNavbar}>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li onClick={handleShowNavbar}>
                            <NavLink to="/mint">Mint</NavLink>
                        </li>
                        <li onClick={handleShowNavbar}>
                            <NavLink to="/staking">Staking</NavLink>
                        </li>
                        <li onClick={handleShowNavbar}>
                            <NavLink to="/spray">Spray-FM</NavLink>
                        </li>
                        <li onClick={handleShowNavbar}>
                            <NavLink to="/merch">Merch</NavLink>
                        </li>
                    </ul>
                    {/* <div className='download_btn'>
                        <NavLink to="/download"><span>Connect Wallet</span></NavLink>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Navbar