// import './DescriptionSection.scss';
import './DescriptionSection.css';
import RoadMapTitle from '../../assets/img/Roadmap Title.png'
import CharitiesImg from '../../assets/img/charities.png'
import OurTeamImg from '../../assets/img/our_team.png'
import OurTeamItem1 from '../../assets/img/IMG_1227.png'
import OurTeamItem2 from '../../assets/img/IMG_1228.png'
import OurTeamItem3 from '../../assets/img/IMG_1229.png'
import OurTeamItem4 from '../../assets/img/IMG_1230.png'
import OurTeamItem5 from '../../assets/img/IMG_1231.png'
import OurTeamItem6 from '../../assets/img/IMG_1232.png'
import OurTeamItem7 from '../../assets/img/IMG_1233.png'
import React, { useCallback, useState, useEffect, useRef } from 'react'
const headerRef1 = React.createRef();
const headerRef2 = React.createRef();
const headerRef3 = React.createRef();
const headerRef4 = React.createRef();

const DescriptionSection = (props) => {
    const { currentY } = props;
    const [backgroundOpacity, setBackgroundOpacity] = React.useState(["rgb(0, 0, 0, 0)", "rgb(0, 0, 0, 0)", "rgb(0, 0, 0, 0)", "rgb(0, 0, 0, 0)"]);
    const [breakPoint, setBreakPoint] = React.useState([1, 1, 1, 1]);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const totalElements = 4;
    const [opacity, setOpacity] = useState(Array(totalElements).fill(0))


    const getHeightByClassName = (className) => {
        const element = document.querySelector(`.${className}`);

        if (element) {
            const elementHeight = element.offsetHeight;
            return elementHeight;
        } else {
            console.warn(`Element with class '${className}' not found.`);
            return 0;
        }
    };

    useEffect(() => {
        const headerHeight1 = headerRef1.current.offsetHeight;
        const headerHeight2 = headerRef2.current.offsetHeight;
        const headerHeight3 = headerRef3.current.offsetHeight;
        const headerHeight4 = headerRef4.current.offsetHeight;
        setBreakPoint([headerHeight1, headerHeight2, headerHeight3, headerHeight4]);
        setBackgroundOpacity(["rgb(0, 0, 0, 0)", "rgb(0, 0, 0, 0)", "rgb(0, 0, 0, 0)", "rgb(0, 0, 0, 0)"]);
        setOpacity([0, 0, 0, 0]);

        let tempRate = 50;
        let startRate = 500;

        if (screenWidth < 1350) tempRate = -150;
        // if (screenWidth < 1200) tempRate = -200;
        if (screenWidth < 1024) tempRate = -250;
        if (screenWidth < 968) tempRate = -200;
        if (screenWidth < 768) {
            tempRate = -200;
            startRate = 300;
        }

        if (currentY < startRate) return;

        const newOpacity = Array.from({ length: totalElements }, (_, index) => {
            const elementTop = breakPoint.slice(0, index).reduce((acc, height) => acc + height, 0) + 300;
            const elementBottom = elementTop + breakPoint[index];

            let visibilityPercentage = (currentY - tempRate - elementTop) / (elementBottom - elementTop);
            if (visibilityPercentage < 0) return 0;
            if (visibilityPercentage > 1) return 1;
            if (visibilityPercentage < 0.5) {
                visibilityPercentage = visibilityPercentage - 0.3;
            } else {
                visibilityPercentage = visibilityPercentage + 0.3;
            }

            // console.log(currentY, " + " + tempRate + " - ", elementTop, " / ", (elementBottom - elementTop), " = ", visibilityPercentage);
            return visibilityPercentage;
        });

        let newBackgroundOpacityArray = [...backgroundOpacity];

        for (let i = 0; i < backgroundOpacity.length; i++) {
            newBackgroundOpacityArray[i] = "rgb(0, 0, 0, " + newOpacity[i] + ")";
        }

        setOpacity(newOpacity);
        setBackgroundOpacity(newBackgroundOpacityArray);

    }, [currentY]);

    return (
        <div className='description_section'>

            <div className="slide firstslide" ref={headerRef1} style={{ willChange: "background", backgroundColor: backgroundOpacity[0] }}>
                <section className='main_description' style={{ willChange: "opacity", opacity: opacity[0] }}>
                    <span>Welcome all... Welcome to our Web3 Hub and the home of the one of the freshest projects of 2024. Solana Spray Club is not only bringing you some totally original artwork to turn the NFT world on its head, we are also bringing some fantastic utility for holders of our artwork.<br />
                        Not only will holders be able to access exclusive content through our revolutionary streaming platform, they will also be earning passive income day by day and week by week.<br />
                        Here at Solana Spray Club everybody gets a slice of the pie..as our project builds over time we will be opening many business ventures under the Solana Spray Club brand and a percentage of profits will be returned to SSC members through airdrops and royalties.<br />
                        All this will be achievable whilst supporting charities that mean most to our team, our community and our partners.<br />
                        What's more, our commitment to honesty and sustainablity is unwavering. No false promises, no smoke and mirrors - just a genuine and transparent journey.
                        Get ready for Spray Season - the adventure awaits!</span>
                </section>
            </div>
            <div className="slide" ref={headerRef2} style={{ willChange: "background", backgroundColor: backgroundOpacity[1] }}>
                <section className='roadmap_description' style={{ willChange: "opacity", opacity: opacity[1] }}>
                    <img src={RoadMapTitle} alt="RoadMapTitle" />
                    <div className='description'>
                        <div>Pre-Mint! Engagement Phase...This stage will no doubt be the toughest, but also the most rewarding. We will engage with as many people and teams as possible and grow a community ready to build a future with.
                            We have lots to prepare for and will continue to work our socks off to ensure we are set up for success!<br /><br /></div>
                        <div>
                            Post Mint! Build phase...We will be committing a massive proportion of royalties to both charity and futher developing SSC and it's inital sub business, Spray-FM. We will deliver our staking platform and tokenomics whilst building on our partnerships and taking our brand to the next level.<br /><br /></div>
                        <div>
                            Months 1-6! Community Phase... It is through these months that Solana Spray Club and Spray-FM will come to life. The bigger picture will become clearer and clearer. Investors will have access to exclusive content, staking, rewards and we will have our merchandise store fully operational.<br /><br /></div>
                        <div>
                            Month 6-12...Expansion Phase...We have a lot of plans in the draft book ready to go in this phase. Having set the foundations for success in the first 3 phases, we will assess our business and expand in the areas that compliment us the most whilst bolstering any weak spots ensuring we stay on an upwards trajectory.<br /><br /></div>
                        <div>
                            12 Months+...Delivery Phase...Over the first 12 months our main aim is to build a sustainably profitable business which delivers a ROI to our community. And although our community would of been recieving rewards and exclusivity over this period we believe it is beyond the first 12 months of growth that the benefits will truly kick in! The passive income and exclusivity will multiply into one of the most rewarding projects online.</div>
                    </div>
                </section>
            </div>
            <div className="slide" ref={headerRef3} style={{ willChange: "background", backgroundColor: backgroundOpacity[2] }}>
                <section className='our_team' style={{ willChange: "opacity", opacity: opacity[2] }}>
                    <img src={OurTeamImg} alt="OurTeamImg" />
                    <div className='our_team_items'>
                        <div className='our_items'>
                            <img src={OurTeamItem1} />
                            <div className='item_description'>
                                <span>Callum</span>
                                <span>Head of Operations</span>
                            </div>
                        </div>
                        <div className='our_items'>
                            <img src={OurTeamItem2} alt="OurTeamImg2" />
                            <div className='item_description'>
                                <span>Base</span>
                                <span>Artist</span>
                            </div>
                        </div>
                        <div className='our_items'>
                            <img src={OurTeamItem3} alt="OurTeamImg3" />
                            <div className='item_description'>
                                <span>Morio Seiki</span>
                                <span>Head of Development</span>
                            </div>
                        </div>
                        <div className='our_items'>
                            <img src={OurTeamItem4} alt="OurTeamImg4" />
                            <div className='item_description'>
                                <span>James</span>
                                <span>Financial Strategist</span>
                            </div>
                        </div>
                        <div className='our_items'>
                            <img src={OurTeamItem5} alt="OurTeamImg5" />
                            <div className='item_description'>
                                <span>Josh</span>
                                <span>Head of Marketing</span>
                            </div>
                        </div>
                        <div className='our_items'>
                            <img src={OurTeamItem6} alt="OurTeamImg6" />
                            <div className='item_description'>
                                <span>Adz</span>
                                <span>Community Manager</span>
                            </div>
                        </div>
                        <div className='our_items'>
                            <img src={OurTeamItem7} alt="OurTeamImg7" />
                            <div className='item_description'>
                                <span>Dan</span>
                                <span>Community Support</span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="slide" ref={headerRef4} style={{ willChange: "background", backgroundColor: "#000" }}>
                <section className='charities' style={{ willChange: "opacity", opacity: 1 }}>
                    <img src={CharitiesImg} alt="CharitiesImg" />
                    <span>Our chosen Charities have not been finalised at this moment in time!
                        Please refer to our discord for further information, we will be hosting polls and Whitelist holders will have a specific channel in which they can express any charities to us.</span>
                    <br />
                    <span>We look forward to working with you and building an understanding of what matters to you most. It is in this way in which we can make our biggest impact for our community.</span>
                </section>
            </div>
        </div>
    )
}

export default DescriptionSection