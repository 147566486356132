import './MintMainSection.css';
import React from 'react';
import HomeEllipse from '../../assets/img/main_page_ellipse.png'
import MintParImg from '../../assets/img/mint_page_img.png'
import MintTitleImg from '../../assets/img/mint_banner.png'
import GradientFillImg from '../../assets/img/Gradient Fill 1.png'

const MainSection = () => {
    return (
        <div className='mintSection'>
            <div className='mint_part'>
                <div className='mint_part_img'>
                    <img src={MintParImg} />
                </div>
                <div className="mint_part_title">
                    <img src={MintTitleImg} />
                    <p>All Mint details to be released in due course.... </p>
                </div>
            </div>
        </div >
    )
}

export default MainSection