import MerchMainSection from "../components/MerchMainSection/MerchMainSection"

const Merch = (props) => {
    const { currentY } = props;
    return (
        <>
            <div className="merch">
                <MerchMainSection />
            </div>
        </>
    )
}

export default Merch