import "./App.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from "./components/Layout/Footer/Footer";
import Navbar from "./components/Layout/Navbar/Navbar";
import Home from "./pages/home";
import Mint from "./pages/mint";
import Merch from "./pages/merch";
import Staking from "./pages/staking";
import React, { useCallback, useState, useEffect, useRef } from 'react';
import Spray from "./pages/spray";

export default function App() {
  const [scrollTop, setScrollTop] = useState(0);
  const handleScrollCapture = (event) => {
    setScrollTop(event.target.scrollTop);
  };

  return (
    <div className="App" onScrollCapture={handleScrollCapture} style={{ overflowY: 'scroll' }}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path='/' element={< Home currentY={scrollTop} />} />
          <Route exact path='/mint' element={< Mint currentY={scrollTop} />} />
          <Route exact path='/merch' element={< Merch currentY={scrollTop} />} />
          <Route exact path='/staking' element={< Staking currentY={scrollTop} />} />
          <Route exact path='/spray' element={< Spray currentY={scrollTop} />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}
