import SprayMainSection from "../components/SprayMainSection/SprayMainSection"

const Spray = (props) => {
    const { currentY } = props;
    return (
        <>
            <div className="spray">
                <SprayMainSection />
            </div>
        </>
    )
}

export default Spray