import './StakingMainSection.css';
import React from 'react';
import HomeEllipse from '../../assets/img/main_page_ellipse.png'
import MainPartImg from '../../assets/img/staking_page_img.png'
import StakingTitleImg from '../../assets/img/staking_banner.png'
import GradientFillImg from '../../assets/img/Gradient Fill 1.png'

const StakingMainSection = () => {
    return (
        <div className='stakingSection'>
            <div className='staking_part'>
                <div className='staking_part_img'>
                    <img src={MainPartImg} />
                </div>
                <div className="staking_part_title">
                    <img src={StakingTitleImg} />
                </div>
            </div>
        </div >
    )
}

export default StakingMainSection